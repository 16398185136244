import React from 'react';
import cx from 'classnames';
import s from './ProductPrice.scss';
import {ProvidedGlobalProps, withGlobalProps} from '../../providers/globalPropsProvider';
import {LayoutContext} from '../ProductPageApp/ProductPageApp';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {unitsTranslations} from './units-translations';
import {AddToCartState} from '@wix/wixstores-client-storefront-sdk/dist/es/src/add-to-cart-service/constants';
import {PriceBreakdown} from '@wix/wixstores-client-common-components/dist/es/src/PriceBreakdown/PriceBreakdown';
import {BasePrice} from '@wix/wixstores-client-common-components/dist/es/src/BasePrice/BasePrice';
import {ProductType} from '../../constants';

@withGlobalProps
@withTranslations('globals.texts')
export class ProductPrice extends React.Component<ProvidedGlobalProps & IProvidedTranslationProps> {
  private renderPrimary() {
    const {t} = this.props;
    const {formattedActualPrice, formattedPassivePrice, frequency} = this.props.globals.currentPrice;

    const primaryTranslationKey = formattedPassivePrice
      ? 'SR_PRODUCT_PRICE_AFTER_DISCOUNT'
      : 'SR_PRODUCT_PRICE_WHEN_THERE_IS_NO_DISCOUNT';

    return (
      <div className={s.formattedPriceContainer}>
        <span data-hook="formatted-primary-price" className={s.primary}>
          {formattedActualPrice}
        </span>
        <span className="sr-only" data-hook="sr-formatted-primary-price">
          {t(primaryTranslationKey)}
        </span>
        {frequency && (
          <span data-hook="subscription-plan-frequency" className={s.subscriptionPlanFrequency}>
            &nbsp;
            {t('PRODUCT_PAGE_SUBSCRIPTION_PRICE_DESCRIPTION', {
              frequencyUnitSingular: this.getFrequencyUnitSingularTranslation(),
            })}
          </span>
        )}
      </div>
    );
  }

  private renderSecondary() {
    const {t} = this.props;
    const {formattedPassivePrice} = this.props.globals.currentPrice;

    return (
      <div className={s.formattedPriceContainer}>
        <span data-hook="formatted-secondary-price" className={s.secondary}>
          {` ${formattedPassivePrice} `}
        </span>
        <span className="sr-only" data-hook="sr-formatted-secondary-price">
          {t('SR_PRODUCT_PRICE_BEFORE_DISCOUNT')}
        </span>
      </div>
    );
  }

  private getFrequencyUnitSingularTranslation(): string {
    const {t} = this.props;
    const {frequency} = this.props.globals.currentPrice;

    switch (frequency) {
      case 'DAY':
        return 'DAY_QA';
      case 'WEEK':
        return t('PRODUCT_PAGE_PLAN_WEEK');
      case 'MONTH':
        return t('PRODUCT_PAGE_PLAN_MONTH');
      case 'YEAR':
        return t('PRODUCT_PAGE_PLAN_YEAR');
    }
  }

  private getUnitTranslations(quantity: number, isAbbreviation: boolean = false): string {
    const {t} = this.props;
    const {baseMeasurementUnit} = this.props.globals.currentPrice;

    if (isAbbreviation) {
      return t(unitsTranslations[baseMeasurementUnit].abbr);
    } else {
      return quantity === 1
        ? t(unitsTranslations[baseMeasurementUnit].singular)
        : t(unitsTranslations[baseMeasurementUnit].plural);
    }
  }

  private renderPrice() {
    const {formattedPassivePrice} = this.props.globals.currentPrice;

    return (
      <>
        {Boolean(formattedPassivePrice) && this.renderSecondary()}
        {this.renderPrimary()}
      </>
    );
  }

  private renderPriceRange(formattedFromPrice: string) {
    const {t} = this.props;
    return (
      <>
        <span className="sr-only" data-hook="sr-formatted-price-range">
          {t('SR_PRODUCT_PRICE_WHEN_THERE_IS_NO_DISCOUNT')}
        </span>
        <span data-hook="price-range-from">
          {t('productPage.price.from.label', {formattedAmount: formattedFromPrice})}
        </span>
      </>
    );
  }

  private readonly sendClickShippingInfoLinkSfEvent = () => {
    const {biLogger, product} = this.props.globals;
    biLogger('clickShippingInfoLinkSf', {
      productId: product.id,
    });
  };

  public render(): JSX.Element {
    const {addToCartState, product} = this.props.globals;
    const {shouldRenderTaxDisclaimer, taxDisclaimer, shippingDisclaimer} = this.props.globals.priceBreakdown;
    const {isZeroPrice, formattedActualPrice, formattedPricePerUnit, formattedFromPrice} =
      this.props.globals.currentPrice;

    const isAddToCartDisabled = addToCartState === AddToCartState.DISABLED;
    const shouldRenderUnitPrice = !!formattedPricePerUnit;
    const hasFormattedPrice = !!formattedActualPrice;

    if ((isAddToCartDisabled && isZeroPrice) || !hasFormattedPrice) {
      return null;
    }

    return (
      <LayoutContext.Consumer>
        {(layoutName) => (
          <div data-hook="product-prices-wrapper">
            <div data-hook="product-price" className={cx(s.prices, s[`prices__${layoutName}`])}>
              {formattedFromPrice ? this.renderPriceRange(formattedFromPrice) : this.renderPrice()}
            </div>
            {shouldRenderUnitPrice && this.renderUnitPrice()}
            {(shouldRenderTaxDisclaimer || shippingDisclaimer?.show) && (
              <PriceBreakdown
                shouldRenderTaxDisclaimer={shouldRenderTaxDisclaimer}
                taxDisclaimerLabel={taxDisclaimer}
                shippingDisclaimer={shippingDisclaimer}
                whenShippingDisclaimerDialogOpen={this.sendClickShippingInfoLinkSfEvent}
                isRTL={this.props.globals.isRTL}
                isDigitalProduct={product.productType === ProductType.DIGITAL}
                className={s.priceBreakdown}
              />
            )}
          </div>
        )}
      </LayoutContext.Consumer>
    );
  }

  private renderUnitPrice() {
    const {formattedPricePerUnit, baseQuantity} = this.props.globals.currentPrice;
    const unitTranslation = this.getUnitTranslations(baseQuantity);
    const srFormattedUnitAndQuantity = `${baseQuantity} ${unitTranslation}`;

    return (
      <BasePrice
        data-hook="product-unit-price"
        className={s.unitPrice}
        formattedPricePerUnit={formattedPricePerUnit}
        baseQuantity={baseQuantity}
        unitTranslation={this.getUnitTranslations(0, true)}
        screenReaderText={srFormattedUnitAndQuantity}
      />
    );
  }
}
